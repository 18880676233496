import React from 'react';

import b2bImage1 from 'assets/images/pages/ecommerce_b2becommerce/1.jpg';
import b2bImage2 from 'assets/images/pages/ecommerce_b2becommerce/2.jpg';
import b2bImage3 from 'assets/images/pages/ecommerce_b2becommerce/3.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';

const EcommerceB2B = () => (
  <Layout>
    <Seo image={{ src: b2bImage1 }} />

    <Title
      image={{ src: b2bImage1 }}
      alt="B2B E-commerce"
      subtitle="Spreek de taal van jouw klanten"
      title="B2B E-commerce"
    >
      Heb of beheer je een business-to-business E-commerce platform of wil je
      deze misschien starten of verder professionaliseren? Dan ben je aan het
      juiste adres!
    </Title>

    <Section>
      <Image
        image={{ src: b2bImage2 }}
        alt="De B2B Magento developers van Batao"
      >
        <Heading size="xl" as="h2">
          De B2B Magento developers van Batao
        </Heading>

        <Text>
          Wij zijn een team met gepassioneerde Magento-developers,
          gespecialiseerd in zowel B2B als B2C E-commerce platforms. We beseffen
          ons dat, hoewel het in beide gevallen om de bouw van Magento webshops
          gaat, business-to-bussines en business-to-consumer webshops een totaal
          andere aanpak nodig hebben. Wij helpen je om in beeld en woord de taal
          van jouw klanten te spreken. Bekijk onze cases om te zien wat we voor
          eerdere B2B klanten hebben gemaakt!
        </Text>
      </Image>
    </Section>

    <Section>
      <Image
        image={{ src: b2bImage3 }}
        alt="Jarenlange ervaring dankzij onze B2B modules"
        position="right"
      >
        <Heading size="xl" as="h2">
          Jarenlange ervaring dankzij onze B2B modules
        </Heading>

        <Text>
          Wij weten wat de behoeften van onze zakelijke klanten zijn. Onze
          jarenlange ervaring uit zich niet alleen in het daadwerkelijk bouwen
          en onderhouden van business-to-business E-commerce platforms, maar ook
          in het ontwikkelen van kwalitatief hoogstaande B2B extensies voor
          Magento. De bekendste hiervan is ongetwijfeld Cart2Quote, onze
          offerte-module die door duizenden B2B wereldwijd wordt ingezet.
        </Text>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default EcommerceB2B;
